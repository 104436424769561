import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { uniqWith } from '../../_core/utils/array.utils';
import { Entity } from '../entities/entities.model';
import { PlanQuery } from '../entities/plan/plan.query';
import { ProgramQuery } from '../entities/program/program.query';
import { RetailerQuery } from '../entities/retailer/retailer.query';
import { CalendarSettings, Column, TableCollection } from './table.model';
import { TableState, TableStore } from './table.store';
import { getColumnDependencies, getEntityFromRawData } from './table.utils';
import { Validation } from '../../../../../api/src/_core/utils';
import { GlobalQuery } from '../global/global.query';
import { LogicalConjunction } from '../../../../../api/src/_core/models/math-operations';

@Injectable({ providedIn: 'root' })
export class TableQuery extends Query<TableState> {
	/**
	 * Retrieves the table rows including which ones should be expanded.
	 */
	tableRows$ = combineLatest([this.select('rows'), this.select('tableSettings')]).pipe(
		map(([rows, settings]) =>
			rows.map((row) => ({
				...row,
				// Add in expanded if the row is currently expanded
				expanded: this.getValue().tableSettings.expandedRows.find((eR) => eR === row.rowId) ? true : false,
				disabled: (this.getValue().tableSettings?.disabledRows ?? [])?.find((eR) => eR === row.rowId) ? true : false,
			}))
		)
	);

	collapsedRows$ = this.tableRows$.pipe(map((rows) => rows.filter((row) => !row.expanded)));

	constructor(
		protected store: TableStore,
		protected retailerQuery: RetailerQuery,
		protected planQuery: PlanQuery,
		protected programQuery: ProgramQuery,
		protected globalQuery: GlobalQuery
	) {
		super(store);
	}

	getColumnCollections() {
		return this.getValue().tableSettings.columns;
	}

	getColumn(columnName: string, endpoints?: ('plans' | 'programs' | 'tactics' | 'invoices')[]) {
		return this.getColumns([columnName], endpoints)?.[0];
	}

	getColumns(
		columnNames?: string[],
		endpoints?: ('plans' | 'programs' | 'tactics' | 'invoices')[],
		visibilitySettings?: { [key: string]: any }
	) {
		const settings: CalendarSettings = this.getValue().tableSettings as CalendarSettings;
		const overallItems = settings.columns.find((collection) => collection.id === 'overall')?.items;
		const dynamicItems = settings.columns.find((collection) => collection.id === 'dynamic')?.items;

		// Search everything unless we specify endpoints.
		if (!endpoints) {
			endpoints = ['plans', 'programs', 'tactics', 'invoices'];
		}

		const endpointItems = settings.columns
			.filter((collection) => endpoints.includes(collection.id as any))
			.map((collection) => collection.items)
			.reduce((acc, val) => acc.concat(val), []);

		const globalSettings = this.globalQuery.getValue().settings;
		const appSection = this.globalQuery.getValue()?.appSection;
		let columns = [...overallItems, ...endpointItems, ...dynamicItems].filter((f) =>
			this.doesColumnPassVisibilityCondition(f, {
				...{ ...globalSettings, section: appSection }, // FIXME Pass app section into settings, change inside column visibility condition to appSection
				...(visibilitySettings || {}),
			})
		);

		if (!columnNames) {
			return columns;
		}

		const allowExternalIds = columnNames.includes('All External Ids');
		const allowMeasurements = columnNames.includes('All Measurements');

		columns = columns.filter((c) =>
			columnNames.find((aC) => {
				const externalIdsCondition = allowExternalIds && c.category === 'External Ids';
				const measurementsCondition = allowMeasurements && c.category === 'Measurements';
				return c.name === aC || externalIdsCondition || measurementsCondition;
			})
		);

		// Make sure this array is unique by the column name
		columns = uniqWith(columns, (a, b) => a.name === b.name);
		return columns;

		// return columns;
	}

	getBudgetColumns() {
		return this.getColumns(
			[
				'Allocated Budget',
				'Estimated Spend',
				'Actual Spend',
				'Allocated Budget From Plan',
				'Budget Allocations',
				'Costs',
				'Planned Program Budget',
			],
			['plans', 'programs', 'tactics', 'invoices']
		);
	}

	getTacticDetailsColumns() {
		return this.getColumns(['Costs', 'Funding Source', 'Vendor(s)'], ['tactics']);
	}
	getStatusColumns() {
		return this.getColumns(['Plan Status', 'Media Plan Status']);
	}

	/**
	 * Get the active columns for the table for a given entity type.
	 */
	getActiveColumns(endpoints: string[], includeCalendarColumns = false) {
		const settings: CalendarSettings = this.getValue().tableSettings as CalendarSettings;
		let activeColumns: string[] = settings?.activeColumns;
		if (includeCalendarColumns) {
			activeColumns = [...new Set([...(activeColumns || []), ...(settings.activeCalendarColumns || [])])];
		}

		// Get all the possible columns for the given endpoints
		const overallItems = settings.columns.find((collection) => collection.id === 'overall')?.items;
		const dynamicItems = settings.columns.find((collection) => collection.id === 'dynamic')?.items;
		const endpointItems = settings.columns
			.filter((collection) => endpoints.includes(collection.id as any))
			.map((collection) => collection.items)
			.reduce((acc, val) => acc.concat(val), []);
		const columns = [...endpointItems, ...overallItems, ...dynamicItems];

		// Now lets iterate through those active columns and find the right ones to pull out.
		const filteredColumns = activeColumns
			.map((aC) => {
				// Allow for special column names to do cool things
				switch (aC) {
					case 'All Measurements':
						const measurementCheck = settings.measurementTypes && settings.measurementTypes.length === 1;

						if (measurementCheck) {
							// Get all columns that are measurements with specified measurement type
							return columns.filter(
								(c) =>
									c.exportPath === 'measurements' &&
									c.name !== 'All Measurements' &&
									c.id.includes(settings.measurementTypes[0])
							);
						} else {
							// Just get all columns that are measurements
							return columns.filter((c) => c.exportPath === 'measurements' && c.name !== 'All Measurements');
						}
					case 'All External Ids':
						// Just get all columns that are external ids
						return columns.filter((c) => c.exportPath === 'externalIds' && c.name !== 'All External Ids');
					default:
						return [columns.find((col) => col.name === aC)];
				}
			})
			.reduce((acc, curr) => acc.concat(curr), [])
			.filter((col) => col?.name);

		// Make sure this array is unique by the column name
		return uniqWith(filteredColumns, (a, b) => a.name === b.name);
	}

	/**
	 * Get all of the dependency field for active columns and join them together.
	 */
	getActiveColumnDependencies(endpoints: string[], includeCalendarColumns?: boolean) {
		return getColumnDependencies(this.getActiveColumns(endpoints, includeCalendarColumns));
	}

	doesColumnPassVisibilityCondition(column: Column, settings: { [key: string]: any }) {
		if (!column.visibilityCondition && !column.visibilityConditions) {
			return true;
		}

		if (column.visibilityCondition) {
			// Test our condition if we have one
			return Validation.validateConditionOnObject({ ...settings }, column.visibilityCondition);
		}

		if (column.visibilityConditions) {
			const conditionResult = column.visibilityConditions.filterConditions.map((visibilityCondition) =>
				Validation.validateConditionOnObject({ ...settings }, visibilityCondition)
			);

			if (column.visibilityConditions.operator === LogicalConjunction.AND) {
				return conditionResult.every((value) => value === true);
			} else {
				return conditionResult.includes(true);
			}
		}

		// Default return false if none of the conditions are met
		return false;
	}

	/**
	 * Leverages the getEntityFromRawData function to get the entity from the table rows, but fills in raw data from the store.
	 * @param id The id of the entity to retrieve.
	 * @param collection The collection of entities to search through
	 * @param parent The parent entity of the collection
	 * @returns
	 */
	getEntityFromRawData(id: string, collection?: TableCollection<Entity>, parent?: Entity) {
		if (!collection) {
			collection = this.getValue().rawData;
		}

		return getEntityFromRawData(id, collection, parent);
	}

	getCalendarEditMode() {
		return this.getValue().tableSettings['calendarEditMode'];
	}
}
