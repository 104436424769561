import { ExternalId } from '../../entities/external-id/external-id.model';
import { Injectable } from '@angular/core';
import { WppOpenRoutingAppType } from '../../../../../../api/src/integrations/wpp-open/models';
import { WppOpenAppInstanceDto } from './dtos/wpp-open-app-instance.dto';
import { FullscreenAppContext } from '@wppopen/core';
import { DefaultHierarchyLevelType } from '@wppopen/core/types/tenant';
import { OsContextValue } from '@wppopen/angular';
import { WppOpenAppContextDto } from './dtos/wpp-open-app-context.dto';
import { WppOpenUserAuthDto } from './dtos/WppOpenUserAuthDto';

@Injectable({
	providedIn: 'root',
})
// TODO Add types
export class WppOpenUtils {
	/**
	 * Determines if the app is running under a single-spa environment.
	 */
	public static isRunningUnderSingleSpa = typeof window !== 'undefined' && !!(window as any)?.singleSpaNavigate;

	/**
	 * Determines if the app is running under embed mode
	 */
	public static isRunningUnderEmbedMode(): boolean {
		let isEmbedded: boolean;
		try {
			isEmbedded = typeof window !== 'undefined' && window?.self.location !== window?.top?.location;
		} catch (e) {
			// In case of cross-origin issues, assume we're embedded.
			isEmbedded = true;
		}
		return isEmbedded;
	}

	/**
	 * Extracts the micro app context from the app context
	 * @param appContext
	 */
	public static extractMicroAppContext(appContext: FullscreenAppContext): WppOpenAppContextDto {
		return {
			name: appContext?.additional?.originalAppName,
			appCustomConfig: appContext?.appCustomConfig,
			appInstance: appContext?.appInstance,
			tenant: appContext?.tenant,
			project: {
				name: appContext?.['project']?.name,
				referenceId: appContext?.['project']?.itemId,
				brand:
					(appContext?.['project'] as any)?.contextHierarchy.find(
						(item) => item.title === DefaultHierarchyLevelType.Brand.toLowerCase()
					) || '',
				client:
					(appContext?.['project'] as any)?.contextHierarchy.find(
						(item) => item.title === DefaultHierarchyLevelType.Client.toLowerCase()
					) || '',
				country:
					(appContext?.['project'] as any)?.contextHierarchy.find(
						(item) => item.title === DefaultHierarchyLevelType.Market.toLowerCase()
					) || '',
			},
		};
	}

	/**
	 * Extracts the os context from the os context
	 * @param osContext
	 */
	public static extractUserAuth(osContext: OsContextValue): WppOpenUserAuthDto {
		const accessToken = osContext.osApi.getAccessToken();

		return {
			id: osContext.osContext?.userDetails?.id,
			firstName: osContext.osContext?.userDetails?.firstname,
			lastName: osContext.osContext?.userDetails?.lastname,
			email: osContext.osContext?.userDetails?.email,
			agency: osContext.osContext?.userDetails?.agency,
			token: accessToken,
		};
	}

	public static getExternalIdsByWppOpenProjectId(externalIds: ExternalId[], embedContext: FullscreenAppContext): ExternalId[] {
		if (!externalIds.length) {
			return null;
		}
		if (!embedContext.project) {
			return null;
		}
		return externalIds.filter((externalId) => {
			if (!externalId.data || Object.keys(externalId.data).length === 0) {
				return false;
			}
			return externalId.value === embedContext.project.id;
		});
	}

	public static getExternalIdByWppOpenProjectIdAndAppType(
		externalIds: ExternalId[],
		wppOpenProjectId: string,
		wppOpenAppType: WppOpenRoutingAppType
	): ExternalId {
		if (!externalIds.length) {
			return null;
		}
		if (!wppOpenProjectId) {
			return null;
		}
		return externalIds.find((externalId) => {
			if (!externalId.data || Object.keys(externalId.data).length === 0) {
				return false;
			}
			return externalId.value === wppOpenProjectId && (externalId.data as WppOpenAppInstanceDto).wppOpenAppType === wppOpenAppType;
		});
	}
}
